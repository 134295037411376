import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

function Zoom() {
  return (
    <StaticImage src="../../../images/zoom.png" alt={'Zoom'} height={55} />
  );
}

Zoom.propTypes = {};

Zoom.defaultProps = {};

export default Zoom;
