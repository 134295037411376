import * as React from 'react';
import styled from 'styled-components';
import { media } from 'styles/theme';

const StyledCisco = styled.svg`
  height: 40px;
  ${media.ns} {
    height: 53px;
  }
`;

function Cisco(props) {
  return (
    <StyledCisco
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 108 57"
      {...props}
    >
      <defs>
        <path
          d="M0 22.769a2.348 2.348 0 003.935 1.545c.434-.403.7-.955.744-1.545v-5.2a2.34 2.34 0 00-4.679 0v5.2"
          id="b"
        />
        <path
          d="M12.95 22.769a2.349 2.349 0 002.34 2.171 2.346 2.346 0 002.339-2.171V11.112a2.341 2.341 0 00-4.679 0V22.77"
          id="c"
        />
        <path
          d="M25.832 27.464a2.345 2.345 0 004.678 0V2.249a2.342 2.342 0 00-4.678 0v25.215"
          id="d"
        />
        <path
          d="M24.026 56.277v-5.002l-.098.043a9.253 9.253 0 01-3.605.915 5.303 5.303 0 01-3.63-1.07 4.644 4.644 0 01-1.58-2.244 5.395 5.395 0 01-.106-3 4.6 4.6 0 011.609-2.566 4.823 4.823 0 012.528-1.09 8.332 8.332 0 014.774.895l.108.056v-5.03l-.228-.061a12.78 12.78 0 00-4.552-.596 10.534 10.534 0 00-4.065.93 9.29 9.29 0 00-3.329 2.572 10.014 10.014 0 00-.182 12.18 9.546 9.546 0 005.292 3.403c2.211.538 4.528.456 6.697-.234l.367-.101"
          id="a"
        />
      </defs>
      <g fill="#00bceb" fillRule="evenodd">
        <use href="#a" />
        <use href="#a" x="49.5" />
        <path d="M41.69 52.125l-.019 4.308.315.054c.989.185 1.987.313 2.99.385a18.1 18.1 0 002.642.051c.842-.06 1.675-.218 2.48-.468a6.862 6.862 0 002.573-1.371 5.19 5.19 0 001.498-2.148 5.912 5.912 0 00-.03-4.324 4.852 4.852 0 00-1.343-1.862 5.568 5.568 0 00-1.97-1.147l-3.25-1.206a1.742 1.742 0 01-.887-.845 1.107 1.107 0 01.036-.986 1.29 1.29 0 01.217-.291 1.75 1.75 0 01.48-.347c.363-.18.755-.293 1.158-.337a6.76 6.76 0 012.072.022c.81.088 1.613.231 2.402.43l.168.037v-3.974l-.31-.067a21.14 21.14 0 00-2.444-.435 12.545 12.545 0 00-3.213-.014 6.945 6.945 0 00-3.699 1.488 4.908 4.908 0 00-1.58 2.178 5.984 5.984 0 00-.003 4.05c.242.65.63 1.237 1.136 1.714.437.422.932.78 1.47 1.065.708.38 1.458.675 2.235.878.257.077.512.158.766.243l.385.141.11.045c.38.136.726.35 1.018.628.202.19.358.423.455.683.06.237.061.485.003.723a1.536 1.536 0 01-.744.892 3.691 3.691 0 01-1.239.387 9 9 0 01-1.92.097 21.973 21.973 0 01-2.507-.334c-.433-.09-.864-.19-1.291-.303l-.16-.04zm-11.144 4.482h4.73V37.846h-4.73zm54.759-13.221a4.934 4.934 0 116.157 7.711 4.934 4.934 0 01-6.157-7.71m-6.867 3.848A9.87 9.87 0 0090.44 56.72a9.629 9.629 0 003.157-17.729 9.934 9.934 0 00-15.16 8.244" />
        <use href="#b" />
        <use href="#c" />
        <use href="#d" />
        <use href="#c" x="25.875" />
        <use href="#b" x="51.75" />
        <use href="#c" x="51.75" />
        <use href="#d" x="51.75" />
        <use href="#c" x="77.625" />
        <use href="#b" x="103.375" />
      </g>
    </StyledCisco>
  );
}

Cisco.propTypes = {};

Cisco.defaultProps = {};

export default Cisco;
