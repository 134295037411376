import * as React from 'react';
// import PropTypes from 'prop-types';

import CardArea from 'components/CardArea';
import Layout, { PageContent } from 'components/Layout';
import Header from 'components/Header';
import Title from 'components/Title';

import { getSearch } from 'utils/helpers';
import { graphql } from 'gatsby';
import routes from 'utils/routes';

import { gtmQuizStart, gtmVideoPlatform } from 'utils/helpers';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import useOptions from 'pageComponents/index/useOptions';
import useSecondaryOptions from 'pageComponents/index/useSecondaryOptions';
import { useQueryParam, ArrayParam, StringParam } from 'use-query-params';

function VideoConferencing({ location }) {
  const { i18n, t } = useTranslation([
    'certifiedSolutions',
    'common',
    'nav',
    'videoConferencing',
  ]);
  const lang = i18n?.language;
  const options = useOptions(t);
  const secondaryOptions = useSecondaryOptions(t);
  const [selectedValue, setSelectedValue] = useQueryParam(
    'videoConferencing',
    ArrayParam
  );
  const [certifiedSolutions, setCertifiedSoltuions] = useQueryParam(
    'certifiedSolutions',
    StringParam
  );

  const navProps = {
    backButton: {
      style: { display: 'none' },
    },
    firstPage: true,
    nextButton: {
      disabled: !selectedValue || typeof certifiedSolutions === 'undefined',
      to: `${routes.roomSize}${getSearch()}`,
    },
  };

  React.useEffect(() => {
    gtmQuizStart();
    // On unmount - send gtm event
    return () => {
      gtmVideoPlatform();
    };
    // eslint-disable-next-line
  }, []);

  function handleSelect(val) {
    let newValue;
    if (!selectedValue) {
      setSelectedValue([val]);
      return scrollToSolutions();
    }
    let shouldScroll = true;
    if (selectedValue.includes(val)) {
      shouldScroll = false;
      newValue = selectedValue.filter((el) => el !== val);
    } else {
      newValue = selectedValue.concat(val);
    }
    setSelectedValue(newValue);
    if (shouldScroll) {
      scrollToSolutions();
    }
  }

  function handleSecondarySelect(val) {
    setCertifiedSoltuions(val);
  }

  function scrollToSolutions() {
    if (typeof certifiedSolutions === 'undefined') {
      const el = document.getElementById('certified-solutions');
      el.scrollIntoView({ behavior: 'smooth' }, true);
    }
  }

  return (
    <Layout navProps={navProps}>
      <Header pageNumber={1} t={t} />
      <PageContent>
        <Title
          instruction={t('common:selectAll')}
          small={lang === 'ja'}
          subTitle={t('common:globalSubTitle')}
          title={t('videoConferencing:title')}
          tooltip={t('videoConferencing:titleTooltip')}
        />
        <CardArea
          columns={3}
          handleSelect={handleSelect}
          selectedValue={selectedValue || []}
          multiselect
          values={options}
        />
        <Title
          small={lang === 'ja'}
          subTitle={null}
          title={t('certifiedSolutions:title')}
          tooltip={t('certifiedSolutions:titleTooltip')}
        />
        <div id="certified-solutions">
          <CardArea
            columns={3}
            handleSelect={handleSecondarySelect}
            selectedValue={certifiedSolutions || null}
            values={secondaryOptions}
          />
        </div>
      </PageContent>
    </Layout>
  );
}

VideoConferencing.propTypes = {};

VideoConferencing.defaultProps = {};

export default VideoConferencing;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        language: { eq: $language }
        ns: { in: ["certifiedSolutions", "common", "nav", "videoConferencing"] }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
