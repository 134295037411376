import * as React from 'react';
// import PropTypes from 'prop-types';
import { CardTitle } from 'components/Card';

function useSecondaryOptions(t) {
  const options = [
    {
      value: 'yes',
      content: <CardTitle>{t('certifiedSolutions:answerYes')}</CardTitle>,
    },
    {
      value: 'no',
      content: <CardTitle>{t('certifiedSolutions:answerNo')}</CardTitle>,
    },
  ];
  return options;
}

export default useSecondaryOptions;
