import * as React from 'react';
// import PropTypes from 'prop-types';

import { CardTitle } from 'components/Card';
import Cisco from './logos/Cisco';
import GoogleMeet from './logos/GoogleMeet';
import MSTeams from './logos/MSTeams';
import Zoom from './logos/Zoom';

function useOptions(t) {
  const options = [
    {
      value: 'zoom',
      content: <Zoom />,
    },
    {
      value: 'cisco',
      content: <Cisco />,
    },
    {
      value: 'microsoftTeams',
      content: <MSTeams height={54} />,
    },
    {
      value: 'googleMeet',
      content: <GoogleMeet />,
    },
    {
      value: 'other',
      content: <CardTitle>{t('common:other')}</CardTitle>,
    },
    {
      value: 'none',
      content: <CardTitle>{t('videoConferencing:noVideo')}</CardTitle>,
    },
  ];
  return options;
}

export default useOptions;
