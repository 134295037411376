import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

function GoogleMeet() {
  return (
    <StaticImage
      alt={'Google meet'}
      height={55}
      src="../../../images/google-meet.png"
    />
  );
}

GoogleMeet.propTypes = {};

GoogleMeet.defaultProps = {};

export default GoogleMeet;
